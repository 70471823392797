import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import "./ToolOfTheDay.css";

const ToolOfTheDay = () => {
  const [toolOfTheDay, setToolOfTheDay] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  // Function to shuffle the array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    const fetchImageUrls = async () => {
      const { data, error } = await supabase
        .from("tool_of_the_day_images")
        .select("image_url");

      if (error) {
        console.error("Error fetching image URLs:", error);
      } else if (data.length > 0) {
        // Shuffle the images for randomness
        const shuffledImages = shuffleArray(data.map((item) => item.image_url));
        setImageUrls(shuffledImages);
      }
    };

    fetchImageUrls();
  }, []);

  useEffect(() => {
    const fetchToolOfTheDay = async () => {
      const today = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format

      const { data, error } = await supabase
        .from("daily_tool")
        .select("*, tools(*)")
        .eq("selected_date", today)
        .single();

      if (error) {
        console.error("Error fetching tool of the day:", error);
      } else if (data) {
        setToolOfTheDay(data.tools);
      } else {
        console.error("No tool of the day found for today.");
      }
    };

    fetchToolOfTheDay();
  }, []);

  return (
    <section className="relative text-base-content py-20 md:py-40 text-center border-t">
      <div className="background-container">
        <div className="row row-1">
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Tool of the Day ${index}`}
              className="background-image"
            />
          ))}
        </div>
        <div className="row row-reverse">
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Tool of the Day ${index}`}
              className="background-image"
            />
          ))}
        </div>
        <div className="row">
          {imageUrls.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Tool of the Day ${index}`}
              className="background-image"
            />
          ))}
        </div>
      </div>
      <div className="relative z-10 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-2">Tool of the Day</h2>
        <div className="w-24 md:w-60 h-2 bg-accent rounded-full mx-auto mb-4"></div>
        {toolOfTheDay ? (
          <div className="flex justify-center">
            <div className="max-w-xs sm:max-w-md md:max-w-lg w-full bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg shadow-lg p-4 sm:p-6 text-gray-900">
              <h3 className="text-2xl sm:text-3xl font-bold mb-4">{toolOfTheDay.title}</h3>
              <img
                src={toolOfTheDay.image_url}
                alt={toolOfTheDay.title}
                className="m-auto w-50 h-32 sm:h-48 object-contain rounded-xl mb-4"
              />
              <p className="text-sm sm:text-lg mb-4">{toolOfTheDay.description}</p>
              <a
                href={toolOfTheDay.url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-accent w-full"
              >
                Learn More
              </a>
            </div>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </section>
  );
};

export default ToolOfTheDay;
