import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initGA, logPageView } from './components/Analytics';
import Header from './components/Header';
import Hero from './components/Hero';
import CategoryNav from './components/CategoryNav';
import ToolsSection from './components/ToolsSection';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import LegalNotice from './components/LegalNotice';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/Footer';
import ToolOfTheDay from './components/ToolOfTheDay'; 
import LatestBlogPosts from './components/LatestBlogPosts';

const App = () => {
    useEffect(() => {
        initGA();
        logPageView();
    }, []);
    const [selectedCategory, setSelectedCategory] = useState('');

    return (
        <Router>
            <div>
                <Header />
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <>
                                <Hero />
                                <ToolOfTheDay /> {/* Include the Tool of the Day */}
                                <CategoryNav onCategorySelect={setSelectedCategory} />
                                <ToolsSection selectedCategory={selectedCategory} />
                                <LatestBlogPosts/>
                            </>
                        } 
                    />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/blog/:title" element={<BlogPost />} />
                    <Route path="/legal-notice" element={<LegalNotice />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
