import React, { useEffect, useState } from "react";
import ToolCard from "./ToolCard";
import { supabase } from "../supabaseClient";
import { motion } from "framer-motion";
import { FaChevronDown } from "react-icons/fa";

const ToolsSection = ({ selectedCategory }) => {
  const [tools, setTools] = useState([]);
  const [visibleTools, setVisibleTools] = useState(9); // Number of tools to show initially
  const cacheKey = selectedCategory ? `tools_${selectedCategory}` : "tools_all";
  const cacheExpiryKey = `${cacheKey}_expiry`;
  const cacheDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

  useEffect(() => {
    const fetchTools = async () => {
      const cachedTools = localStorage.getItem(cacheKey);
      const cacheExpiry = localStorage.getItem(cacheExpiryKey);
      const now = Date.now();

      // Fetch the latest created_at timestamp from Supabase
      const { data: latestTool, error: timestampError } = await supabase
        .from("tools")
        .select("created_at")
        .order("created_at", { ascending: false })
        .limit(1)
        .single();

      if (timestampError) {
        console.error("Error fetching latest tool timestamp:", timestampError);
      } else {
        const cachedLastUpdated = localStorage.getItem("last_updated");

        // If the latest tool's created_at is newer than the cached timestamp, invalidate cache
        if (
          latestTool?.created_at !== cachedLastUpdated ||
          !cachedTools ||
          now > parseInt(cacheExpiry, 10)
        ) {
          let query = supabase
            .from("tools")
            .select("*")
            .order("created_at", { ascending: false }); // Order by created_at descending

          if (selectedCategory) {
            query = query.eq("category", selectedCategory);
          }

          const { data, error } = await query;

          if (error) {
            console.error("Error fetching tools:", error);
          } else {
            setTools(data);
            // Cache the data and set an expiry time
            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(
              cacheExpiryKey,
              (now + cacheDuration).toString()
            );
            localStorage.setItem("last_updated", latestTool?.created_at);
          }
        } else {
          setTools(JSON.parse(cachedTools));
        }
      }
    };

    fetchTools();
  }, [selectedCategory, cacheKey, cacheExpiryKey]);

  const showMoreTools = () => {
    setVisibleTools((prev) => prev + 9); // Show 9 more tools each time
  };

  return (
    <section className="py-10 bg-base-100 text-base-content">
      <div className="container mx-auto px-4">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          {tools.slice(0, visibleTools).map((tool, index) => (
            <ToolCard key={index} {...tool} />
          ))}
        </motion.div>
        {visibleTools < tools.length && (
          <div className="flex justify-center mt-8">
            <button
              onClick={showMoreTools}
              className="flex items-center px-4 py-2 bg-accent text-white rounded-full hover:bg-accent-focus transition-colors duration-300"
            >
              Show More <FaChevronDown className="ml-2" />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ToolsSection;
