import React, { useState } from 'react';
import { FaIcons, FaPaintBrush, FaPalette, FaFont, FaLayerGroup, FaImages, FaMagic, FaHandPointer, FaShapes } from 'react-icons/fa';

const categories = [
    { name: 'All', icon: null },
    { name: 'Icons', icon: FaIcons },
    { name: 'CSS Tools', icon: FaPaintBrush },
    { name: 'Colors', icon: FaPalette },
    { name: 'Fonts', icon: FaFont },
    { name: 'UI Libraries', icon: FaLayerGroup },
    { name: 'Image Libraries', icon: FaImages },
    { name: 'Animations Tools', icon: FaMagic },
    { name: 'Buttons', icon: FaHandPointer },
    { name: 'Shapes', icon: FaShapes },
];

const CategoryNav = ({ onCategorySelect }) => {
    const [selectedCategory, setSelectedCategory] = useState('All');

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        onCategorySelect(category === 'All' ? '' : category); // Pass an empty string for 'All'
    };

    return (
        <nav className="bg-base-100 text-base-content py-5 shadow-sm overflow-x-auto whitespace-nowrap border-y">
            <div className="container mx-auto px-4 flex justify-start space-x-4 max-w-full">
                {categories.map((category) => (
                    <button
                        key={category.name}
                        onClick={() => handleCategorySelect(category.name)}
                        className={`flex items-center space-x-2 px-4 py-2 rounded-full transition-all ${
                            selectedCategory === category.name
                                ? 'bg-accent text-white shadow'
                                : 'bg-gray-100 text-gray-700'
                        } hover:bg-accent hover:text-white`}
                    >
                        {category.icon && <category.icon className="text-lg" />}
                        <span>{category.name}</span>
                    </button>
                ))}
            </div>
        </nav>
    );
};

export default CategoryNav;
