import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient"; // Make sure the path is correct for your project
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const LatestBlogPosts = () => {
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    const fetchLatestPosts = async () => {
      const { data, error } = await supabase
        .from("blog_posts")
        .select("*")
        .order("created_at", { ascending: false })
        .limit(3); // Fetch the 3 latest blog posts

      if (error) {
        console.error("Error fetching latest blog posts:", error);
      } else {
        setLatestPosts(data);
      }
    };

    fetchLatestPosts();
  }, []);

  const slugify = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  return (
    <section className="py-10 bg-base-100 text-base-content mt-10">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8">Latest Blog Posts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {latestPosts.map((post, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <img
                src={post.image_url}
                alt={post.title}
                className="w-full h-40 object-cover rounded-lg mb-4"
              />
              <h3 className="text-2xl font-bold mb-2">{post.title}</h3>
              <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
              <Link
                to={`/blog/${slugify(post.title)}`}
                className="text-accent font-bold hover:underline"
              >
                Read More
              </Link>
            </motion.div>
          ))}
          {/* Show More Button */}
          <div className="flex mt-8">
            <Link to="/blog" className="btn btn-accent">
              Show More Blog Posts
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestBlogPosts;
