import React from "react";
import { motion } from "framer-motion";
import backgroundImg from "../assets/img/Section-1-Background.png";
import floatingIcons from "../assets/img/Section-1-Floating-Icons.png";

const Hero = () => {
  return (
    <section
      className="relative text-base-content py-20 md:py-32 text-center"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Floating Icons Image - Only visible on md screens and above */}
      <img
        src={floatingIcons}
        alt="Floating Icons"
        className="hidden md:block absolute inset-0 w-full h-full object-contain pointer-events-none md:pt-20"
      />

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="container mx-auto px-2 relative z-10"
      >
        <h1 className="text-3xl md:text-5xl font-extrabold mb-10 mt-10 md:mb-16 tracking-wider leading-tight md:leading-normal">
          Your Go-To Hub <br className="hidden md:block" />
          for Essential Design <br className="hidden md:block" />
          Tools Crafted for Developers.
        </h1>
        <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.5, duration: 1 }}
    className="flex flex-col md:flex-row md:justify-center items-center space-y-4 md:space-y-0 md:space-x-6 mt-8 md:mt-12"
>
    <a
        href="mailto:meljcontact@gmail.com"
        className="btn w-6/12 md:w-auto px-8 py-3 bg-black text-white border-2 border-black rounded-full"
    >
        Suggest a Tool
    </a>
    <a
        href="mailto:meljcontact@gmail.com"
        className="btn w-6/12 md:w-auto px-8 py-3 bg-white text-black border-2 border-black rounded-full"
    >
        Contact Us
    </a>
</motion.div>

      </motion.div>
    </section>
  );
};

export default Hero;
