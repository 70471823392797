import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { logPageView } from './Analytics'; // Import your Google Analytics logging function

const Blog = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        // Log a page view when the Blog component is loaded
        logPageView();

        const fetchPosts = async () => {
            const { data, error } = await supabase
                .from('blog_posts')
                .select('*');

            if (error) {
                console.error('Error fetching blog posts:', error);
            } else {
                setPosts(data);
            }
        };

        fetchPosts();
    }, []);

    const slugify = (title) => {
        return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    };

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMMM d, yyyy');
    };

    return (
        <section className="py-16 bg-base-100 text-base-content mt-20">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold mb-12">Fastools Blog</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {posts.map((post, index) => (
                        <motion.div
                            key={index}
                            className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, ease: 'easeOut' }}
                        >
                            <div className="absolute top-2 left-6 bg-accent text-white text-xs font-semibold px-3 py-1 rounded">
                                {formatDate(post.created_at)}
                            </div>
                            <img src={post.image_url} alt={post.title} className="w-full h-40 object-cover rounded-lg mb-4 mt-4" />
                            <h3 className="text-2xl font-bold mb-2">{post.title}</h3>
                            <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>
                            <Link to={`/blog/${slugify(post.title)}`} className="text-accent font-bold hover:underline">Read More</Link>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Blog;
