import React from 'react';
import { motion } from 'framer-motion';

const ToolCard = ({ image_url, title, description, price, category, isNew, url }) => {
    // Determine the badge color based on the price
    const priceBadgeColor = {
        'Free': 'bg-green-100 text-green-700',
        'Paid': 'bg-yellow-100 text-green-700',
        'Free & Paid': 'bg-blue-100 text-blue-700',
    }[price] || 'bg-gray-100 text-gray-700';

    return (
        <motion.a 
            href={url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="block"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: 'easeOut' }}
        >
            <div className="relative bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 w-full max-w-lg h-56"> {/* Fixed height for uniformity */}
                <div className="flex justify-between">
                    <img src={image_url} alt={title} className="w-24 h-16 object-contain rounded-lg" />
                    <div className="flex flex-col items-end ml-4"> {/* Added margin to move the price and category away from the title */}
                        <span className={`text-xs font-semibold px-3 py-1 rounded-full ${priceBadgeColor} mb-2`}>
                            {price}
                        </span>
                        <span className="bg-gray-100 text-gray-700 text-xs font-semibold px-3 py-1 rounded-full">
                            {category}
                        </span>
                    </div>
                </div>
                <div className="mt-4 flex items-center space-x-2"> {/* Added space-x-2 for spacing between title and New badge */}
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{title}</h3>
                    {isNew && (
                        <span className="bg-green-600 text-white text-xs font-semibold px-2 py-1 rounded-full">
                            New
                        </span>
                    )}
                </div>
                <p className="text-gray-600 text-sm line-clamp-3"> {/* Limit description to 3 lines */}
                    {description}
                </p>
            </div>
        </motion.a>
    );
};

export default ToolCard;
