import React from 'react';

const LegalNotice = () => {
    return (
        <div className="container mx-auto px-4 py-16 mt-12">
            <h1 className="text-4xl font-bold mb-8">Legal Notice</h1>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">1. Company Information</h2>
                <p><strong>Company Name:</strong> Fastools</p>
                <p><strong>Email:</strong> meljcontact@gmail.com</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">2. Terms of Use</h2>
                <p>By accessing and using this website, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by the above, please do not use this site.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property</h2>
                <p>All content on this website, including but not limited to text, graphics, logos, icons, images, audio clips, and software, is the property of Fastools and is protected by international copyright laws. Unauthorized use of any content may violate copyright, trademark, and other laws.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">4. Liability</h2>
                <p>Fastools is not responsible for any direct or indirect damages that may arise from the use of this website. This includes, but is not limited to, damages due to lost data, lost profits, or business interruption.</p>
            </section>

            <section>
                <h2 className="text-2xl font-semibold mb-4">5. Governing Law</h2>
                <p>This legal notice is governed by and construed in accordance with the laws of Canada. Any disputes arising in connection with this legal notice will be subject to the exclusive jurisdiction of the courts of [Your Country].</p>
            </section>
        </div>
    );
};

export default LegalNotice;
