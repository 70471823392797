import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FaArrowLeft } from 'react-icons/fa'; // Importing the arrow icon from react-icons

const BlogPost = () => {
    const { title } = useParams(); 
    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            if (!title) {
                setError('Title is missing from the URL.');
                return;
            }

            const formattedTitle = title.replace(/-/g, ' ');
            const { data, error } = await supabase
                .from('blog_posts')
                .select('*')
                .ilike('title', `%${formattedTitle}%`);

            if (error) {
                console.error('Error fetching blog post:', error);
                setError('There was an error fetching the blog post.');
            } else if (data.length === 0) {
                setError('No blog post found.');
            } else {
                setPost(data[0]);
            }
        };

        fetchPost();
    }, [title]);

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMMM d, yyyy');
    };

    return (
        <section className="py-16 bg-base-100 text-base-content mt-10">
            <div className="container mx-auto px-4 lg:px-40">
                <div className="text-left mb-8">
                    <Link to="/blog" className="text-black flex items-center">
                        <FaArrowLeft className="mr-2" /> {/* Adding the arrow icon here */}
                        Back to Blog
                    </Link>
                </div>
                {error ? (
                    <p>{error}</p>
                ) : post ? (
                    <>
                        <div className="flex mb-4">
                            <p className="bg-accent text-gray-700 text-sm px-4 py-2 rounded-full">
                                {formatDate(post.created_at)}
                            </p>
                        </div>
                        <h1 className="text-4xl md:text-5xl font-extrabold mb-12">{post.title}</h1>
                        {post.image_url && (
                            <img 
                                src={post.image_url} 
                                alt={post.title} 
                                className="w-full h-auto object-cover rounded-lg mb-12"
                            />
                        )}
                        <div
                            className="prose prose-lg prose-indigo max-w-none mx-auto mb-8"
                            dangerouslySetInnerHTML={{ __html: post.content }}
                        />
                    </>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </section>
    );
};

export default BlogPost;
