import React from 'react';
import { Link } from 'react-router-dom'; 
import { FaTwitter, FaTiktok } from 'react-icons/fa';
import logo from '../assets/img/fastools_logo_blanc.png'; 

const Footer = () => {
    return (
        <footer className="bg-gray-900 text-gray-100 py-8 mt-12">
            <div className="container mx-auto px-6">
                <div className="flex flex-col md:flex-row justify-between items-start space-y-6 md:space-y-0 md:space-x-12">
                    <div className="flex flex-col items-start">
                        <img src={logo} alt="Fastools Logo" className="h-8 md:h-10 mb-2" />
                        <p className="text-sm text-gray-400">Your go-to hub for essential design tools crafted for developers.</p>
                    </div>
                    <div className="flex flex-col md:flex-row items-start md:items-center md:space-x-4 space-y-2 md:space-y-0">
                        <Link to="/blog" className="text-sm hover:text-accent">Blog</Link>
                        <a href="mailto:meljcontact@gmail.com" className="text-sm hover:text-accent">Sponsor Us</a>
                        <a href="mailto:meljcontact@gmail.com" className="text-sm hover:text-accent">Contact Us</a>
                        <Link to="/legal-notice" className="text-sm hover:text-accent">Legal Notice</Link>
                        <Link to="/privacy-policy" className="text-sm hover:text-accent">Privacy Policy</Link>
                    </div>
                    <div className="flex space-x-4">
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-accent">
                            <FaTwitter />
                        </a>
                        <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" className="hover:text-accent">
                            <FaTiktok />
                        </a>
                    </div>
                </div>
                <div className="mt-6 border-t border-gray-700 pt-4">
                    <p className="text-sm text-gray-400">© 2024 Fastools. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
