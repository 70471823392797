import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-16 mt-12">
            <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                <p>We value your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and share your personal information.</p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">2. Data We Collect</h2>
                <p>We may collect and process the following data about you:</p>
                <ul className="list-disc ml-6">
                    <li>Contact detail such email address.</li>
                    <li>Information you provide when filling out forms on our website.</li>
                    <li>Details of your visits to our website and the resources you access.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Data</h2>
                <p>Your data may be used for the following purposes:</p>
                <ul className="list-disc ml-6">
                    <li>To provide you with the information, products, and services you request from us.</li>
                    <li>To improve our website and ensure that content is presented in the most effective manner.</li>
                    <li>To notify you about changes to our services.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">4. Sharing Your Data</h2>
                <p>We will not share your personal data with third parties except in the following circumstances:</p>
                <ul className="list-disc ml-6">
                    <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation.</li>
                    <li>To protect the rights, property, or safety of Fastools, our customers, or others.</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
                <p>You have the right to access, correct, or delete your personal data that we hold. You also have the right to withdraw your consent to the processing of your data at any time.</p>
            </section>

            <section>
                <h2 className="text-2xl font-semibold mb-4">6. Contact Us</h2>
                <p>If you have any questions about this privacy policy or our treatment of your personal data, please contact us at meljcontact@gmail.com.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
