import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/img/fastools_logo.png'; // Import the logo image

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <motion.header 
            initial={{ y: -100 }} 
            animate={{ y: 0 }} 
            transition={{ duration: 0.5, ease: 'easeOut' }}
            className={`fixed top-0 left-0 w-full z-20 transition-all duration-300 ${isScrolled || location.pathname === '/blog' ? 'bg-white text-gray-900 shadow-md' : 'bg-transparent text-black'}`}
        >
            <div className="container mx-auto flex justify-between items-center py-4 px-6">
                <Link to="/">
                    <img src={logo} alt="Fastools Logo" className="h-8 md:h-10" /> {/* Adjust the height as needed */}
                </Link>
                
                {/* Mobile menu button */}
                <button 
                    className="text-2xl md:hidden focus:outline-none z-50" // Ensure button stays on top
                    onClick={toggleMenu}
                >
                    {menuOpen ? <FaTimes /> : <FaBars />}
                </button>

                {/* Navigation Menu */}
                <nav className={`md:flex items-center ${menuOpen ? 'fixed top-0 left-0 w-full h-96 bg-white text-center z-40' : 'hidden'} md:relative md:bg-transparent md:text-left md:space-x-6`}>
                    <div className="flex flex-col md:flex-row md:space-x-6 mt-20 md:mt-0"> {/* Add margin-top on mobile to prevent overlap with header */}
                        <Link to="/" className="block text-sm py-4 hover:text-accent">Home</Link>
                        <Link to="/Blog" className="block text-sm py-4 hover:text-accent">Blog</Link>
                        {/* <Link to="/sponsor-us" className="block text-sm py-4 hover:text-accent">Sponsor Us</Link> */}
                        <Link to="mailto:meljcontact@gmail.com" className="block text-sm py-4 hover:bg-accent bg-gray-200 text-black px-4 rounded-full mx-auto md:mx-0">Suggest a Tool</Link>
                    </div>
                </nav>
            </div>
        </motion.header>
    );
};

export default Header;
